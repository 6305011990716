// Imports libraries
import React from "react";

// Imports locales
import locale_bottom from "../../locale/bottom.json";
import locale_home from "../../locale/home.json";

// Imports components
import Column from "../atoms/column";
import Spacer from "../atoms/spacer";
import Banner from "../molecules/banner";
import { CookieToggle } from "./consent";

// Creates custom footer
export default
function Bottom
()
{

    const text_bottom = JSON.parse(JSON.stringify(locale_bottom));
    const text_home = JSON.parse(JSON.stringify(locale_home));

    return (
        <footer class="bottom"><Banner isFooter="true" theme="accent"><Column size="30" side="left">

            <h3>{text_bottom["privacy"]["meta"]["heading"]}</h3>
            <p>{text_bottom["privacy"]["meta"]["description"]}</p>
            <a target="_blank" href={text_bottom["privacy"]["action"]["link"]}><button class="primary">{text_bottom["privacy"]["action"]["call"]}</button></a>
        
            <Spacer />

            <h3>{text_bottom["support"]["meta"]["heading"]}</h3>
            <p>{text_bottom["support"]["meta"]["description"]}</p>
            <a href={text_home["banners"]["3"]["link-1"]}><button class="primary">{text_home["banners"]["3"]["action-1"]}</button></a>
            <a class="link accent" id="contact" href={text_home.banners["3"].link-2}>{text_home["banners"]["3"]["action-2"]}</a>

            <Spacer />
            
            <p><b>Copyright © 2020-{new Date().getFullYear()} Performance Edge</b></p>
            <a class="link accent" target="_blank" href="https://www.performance-edge.co.uk/"><p>{text_bottom["copyright"]["rights"]}</p></a>
            <a class="link accent" target="_blank" href="https://www.harmanised.co.uk/"><p>{text_bottom["copyright"]["development"]}</p></a>
            <a class="link accent" target="_blank" href="https://www.linkedin.com/in/"><p>{text_bottom["copyright"]["testing"]}</p></a>
            
                
        </Column></Banner></footer>
    );

}